import classNames from 'classnames';
import React from 'react';
import styles from './styles/boilerplate.module.scss';

export const AdminContainer = ({ children, inputDisabled = false }) => (
  <div id={'adminContainer'} className={classNames(
    styles.container,
    inputDisabled ? styles.disableInput : ''
  )}>
    <div className={styles.background} />
    {children}
  </div>
);

export const Screen = ({ children }) => (
  <div className={styles.screen}>
    {children}
  </div>
);

export const Content = ({ children, noBottomPadding = false }) => (
  <div className={classNames(
    styles.inner,
    noBottomPadding ? styles.noBottomPadding : ''
  )}>
    {children}
  </div>
);