import AdminBodyReset from '../../../admin/AdminBodyReset';
import { Content, Screen } from '../../../admin/boilerplate/Screen';
import React from 'react';
import Spinner from '../Spinner/Spinner';
import styles from './load-screen.module.scss';

const LoadScreen = () => {
  return (
    <Screen>
      <Content>
        <AdminBodyReset />
        <div className={styles.connecting}>Connecting...</div>
        <Spinner cssClass={styles.spinner} />
      </Content>
    </Screen>
  )
}

export default LoadScreen;

