import React from 'react';
import { Style } from 'react-style-tag';

const AdminBodyReset = () => (
  <Style>
    {`
    body, html, #root {
      height: 100%;
      background: #1e2125;
    }
  `}
  </Style>
);

export default AdminBodyReset;