import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { spinner, spinnerHarness, spinnerHarnessBg } from './spinner.module.scss';

const Spinner = ({ colour = 'white', cssClass = null, fillScreen = false, delayShowSeconds = 0, showBg = false }) => {
  const [show, setShow] = useState(0);
  const spin = <div className={classNames(spinner, cssClass ? cssClass : '')} style={{ borderTopColor: `${colour}`, opacity: show }} />;
  const timeout = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => setShow(1), delayShowSeconds * 1000);
    return () => { if (timeout.current) clearTimeout(timeout.current) }
  }, [delayShowSeconds]);

  if (fillScreen) {
    return (
      <div className={classNames(spinnerHarness, showBg ? spinnerHarnessBg : '')}>
        {spin}
      </div>
    )
  }
  else return spin;
};

export default Spinner;